/* eslint-disable prefer-destructuring */
if (module.hot) {
  module.hot.accept();
}

/* Hide ESF banner on click of close button */

// const closeButton = document.getElementById('close-banner');
// const esfBanner = document.querySelector('.step-out-banner');
const menuButton = document.getElementById('menu-button');
const LOCAL_STORAGE_KEY = 'markkuserapp.user_data';

function getUserData() {
  const userData = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
  return userData;
}

function openUserProfile() {
  const userObj = getUserData();
  const urlSuffix = `/profile/${userObj.displayName[0]}`;
  window.location.href = urlSuffix;
}

function openSigninPage() {
  const urlSuffix = `/login/?r=${window.location.pathname + window.location.search}`;
  window.location.href = urlSuffix;
}

function setupLoginState() {
  const userObj = getUserData();
  const isUserLoggedIn = userObj ? true : false;
  const loggedIn = document.getElementById('logged-in');
  const loggedOut = document.getElementById('logged-out');
  const userImageEl = document.getElementById('user-image');
  // const logoutButtonEl = document.getElementById('logout-button');
  const signinButton = document.getElementById('signin-button');
  const userNameText = document.getElementById('user-name');

  if (isUserLoggedIn) {
    loggedIn.classList.remove('display-none');
    loggedOut.classList.add('display-none');
    userNameText.innerHTML = userObj.displayName[0];
    if (userObj.imageUrl && userObj.imageUrl !== 'null') {
      userImageEl.setAttribute('src', userObj.imageUrl)
    } else {
      const icotarUrl = `https://icotar.com/initials/${userObj.displayName[0]}`;
      userImageEl.setAttribute('src', icotarUrl);
    }
    // if (location.href.includes(userObj.displayName[0])) {
    // logoutButtonEl.classList.remove('display-none');
    // } else {
    // logoutButtonEl.classList.add('display-none');
    // }

    loggedIn.addEventListener('click', openUserProfile);
  } else {
    loggedIn.classList.add('display-none');
    loggedOut.classList.remove('display-none');
    signinButton.addEventListener('click', openSigninPage);
  }
}

function showNormalContent() {
  const navBar = document.getElementById('nav-bar');
  const footerEl = document.getElementById('footer');
  // const introTextEl = document.getElementById('intro-text-content');
  // const ratingCardNope = document.querySelector('.rating.nope');
  // const ratingCardOkay = document.querySelector('.rating.okay');
  const sectionsArray = document.getElementsByTagName('section');

  // eslint-disable-next-line no-restricted-syntax
  for (const sectionEl of sectionsArray) {
    sectionEl.classList.remove('lg-hide');
  }

  navBar.classList.remove('lg-hide');
  setTimeout(() => {
    navBar.classList.add('appear');
    // introTextEl.classList.add('appear');
    // ratingCardNope.classList.add('appear');
    // ratingCardOkay.classList.add('appear');
  }, 2000);
  footerEl.classList.remove('lg-hide');
}

function setAppStoreLink() {
  const appStoreCTAEl = document.getElementById('apple-store-cta');
  if (window.location.search && window.location.search.includes('beta') || window.location.href.includes('beta')) {
    appStoreCTAEl.href = 'https://testflight.apple.com/join/zj0HE6zQ';
  } else {
    appStoreCTAEl.href = 'https://apps.apple.com/us/app/markk-share-place-stories/id1441663354';
  }
}

function initialiseCarouselAutoplay() {
  const carouselContainer = document.getElementById('carousel-container');
  // carouselContainer.addEventListener('click', (event) => {
  //   event.preventDefault();
  //   window.open('https://www.getmarkk.com/challenges', '');
  // });

  let scrollAmount = Math.floor(carouselContainer.scrollWidth / 4);

  if (carouselContainer.scrollLeft >= carouselContainer.scrollWidth / 4 * 3) {
    scrollAmount = -carouselContainer.scrollWidth;
  }

  const scrollOptions = {
    top: 0,
    left: scrollAmount,
  };

  scrollOptions.behavior = 'smooth';
  // if (scrollAmount > 0) {
  //   scrollOptions.behavior = 'smooth';
  // }

  carouselContainer.scrollBy(scrollOptions);
}

/*
function makeGIFVanish() {
  const gifContainer = document.getElementById('gif-container');
  gifContainer.classList.add('leave');

  // // TODO: Use CSS variable here?
  // setTimeout(() => {
  //   // gifContainer.classList.add('display-none');
  // }, 1100);
}
*/


/*
function setupIntroAnimation() {
  // 0. Start with the GIF. make it vanish in a sec.
  setTimeout(() => {
    makeGIFVanish();

    // 1. And now show the normal content
    showNormalContent();
  }, 1000);

  // 2. Hide it now, zoom away.
  // 3. Bring in other elements. Swooooop...
}
*/

window.addEventListener('DOMContentLoaded', () => {
  setupLoginState();
  showNormalContent();

  setAppStoreLink();

  // TODO: Keep calling in interval
  // const carouselInterval = setInterval(() => {
  //   initialiseCarouselAutoplay();
  // }, 3000);

  // Intsersection Observer
  /*
    let options = {
      root: null,
      rootMargin: '0px 0px 300px 0px',
      thereshold: 1.0,
    };

    const howItWorks = document.querySelectorAll('.step, .arrow');
    console.log(howItWorks);

    const observer = new IntersectionObserver((entries) => {

      entries.forEach((entry) => {
        if (entry.intersectionRatio > 0) {
          entry.target.style.animation = `anim1 1s forwards ease-out`;
        } else {
          entry.target.style.animation = `none`;
        }
      });
    }, options);

    howItWorks.forEach((element) => {
      observer.observe(element);
    });
  */

  /*
  setTimeout(() => {
    // if (window.innerWidth >= 1024) {
    setupIntroAnimation();
    // }
  }, 0);
  */
});

// function hideESFBanner() {
//   esfBanner.style.display = 'none';
//   const root = document.documentElement;

//   root.style.setProperty('--navbar-top-position', '64px');
//   root.style.setProperty('--navbar-top', '0');
// }

// function navigateToESF(event) {
//   if (event.target !== closeButton) {
//     window.location.href = '/stepoutfordtla';
//   }
// }

function toggleMenu() {
  const menuButtonImg = document.getElementById('icon-menu');
  const closeButtonImg = document.getElementById('icon-close');
  const navMenu = document.getElementById('nav-menu');
  const scrim = document.getElementById('scrim');
  // const bodyEl = document.getElementsByTagName('body')[0];

  if (!(navMenu.style.right === '0px')) {
    // show menu
    navMenu.style.right = '0px';
    scrim.style.display = 'block';
    scrim.style.opacity = 1;

    menuButtonImg.classList.add('display-none');
    closeButtonImg.classList.remove('display-none');
    // bodyEl.style.overflow = 'hidden';
  } else {
    // hide menu
    navMenu.style.right = '-100vw';
    scrim.style.opacity = 0;
    scrim.style.display = 'none';

    menuButtonImg.classList.remove('display-none');
    closeButtonImg.classList.add('display-none');
    // bodyEl.style.overflow = 'auto';
  }
}

// closeButton.addEventListener('click', hideESFBanner);
// esfBanner.addEventListener('click', navigateToESF);
// menuButton.addEventListener('click', toggleMenu);
